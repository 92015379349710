import React from "react";
import {graphql} from "gatsby";
import {isEmpty} from "lodash";
import TestimonialCard from "components/testimonials/testimonial-card";
import Img from "components/image";
import DefaultLayout from "components/layout/default";
import {IMAGE_BACKGROUND_PROPS, EXTERNAL_LINK_PROPS} from "constants/index";
import style from "styles/pages/products.module.css";
import layoutStyle from "styles/layout.module.css";
import radiusTopCapImage from "images/product-radius-top-cap.svg";
import radiusBottomCapImage from "images/product-radius-bottom-cap.svg";
import radiusCapGrayTopSmImage from "images/radius-cap-gray-top-sm.svg";
import radiusCapGrayBottomSmImage from "images/radius-cap-gray-bottom-sm.svg";
import radiusCapBlackTopSmImage from "images/radius-cap-black-top-sm.svg";
import radiusCapBlackBottomSmImage from "images/radius-cap-black-bottom-sm.svg";
import wardenImage from "images/product-warden.svg";
import icon1Image from "images/warden-icon-1.png";
import icon2Image from "images/warden-icon-2.png";
import icon3Image from "images/warden-icon-3.png";
import icon4Image from "images/warden-icon-4.png";
import icon5Image from "images/warden-icon-5.png";
import icon6Image from "images/warden-icon-6.png";
import iconBillImage from "images/warden-icon-bill.svg";
import iconBuyImage from "images/warden-icon-buy.svg";
import iconLaunchImage from "images/warden-icon-launch.svg";
import icon1mImage from "images/warden-icon-1m.svg";
import icon12mImage from "images/warden-icon-12m.svg";
import zaveLogo from "images/logos/zave-logo.png";

function ProductWardenPage(props) {
  let pageContent = props.data.pageContent.childProductPagesJson;
  let [mainImageLoaded, setMainImageLoaded] = React.useState(false);
  let mainImageRef = React.useRef();

  React.useEffect(() => {
    setMainImageLoaded(mainImageRef.current.complete);
  }, [mainImageRef]);

  function handleMainImageLoad() {
    setMainImageLoaded(true);
  }

  function renderVerticalLine() {
    let height = "0%";
    if (mainImageLoaded) height = "64vh";
    return <div className={`${style.verticalLine} absolute top-0 w-px bg-white hidden md:block`}
      style={{height}}/>;
  }

  function renderSection1() {
    return <section className="relative min-h-screen">
      <div className="container mx-auto px-8 pt-4 pb-16 md:py-32 z-10 relative">
        <div className="text-center px-8 md:px-0">
          <img ref={mainImageRef} className="inline-block pointer-events-none"
            src={wardenImage} width={340}
            alt={pageContent.section1.headerText}
            onLoad={handleMainImageLoad}/>
        </div>
        <div className="relative flex">
          {renderVerticalLine()}
          <div className="md:w-1/2 flex-none"/>
          <div className="ml-0 md:ml-16">
            <h3 className = "font-bold text-4xl lg:text-6xl leading-tight mt-4">
              {pageContent.section1.headerText}
            </h3>
            <p>
              {pageContent.section1.subHeaderText}
            </p>
            <div>
              <a href={pageContent.section1.ctaUrl} className="btn btn-orange mt-6 inline-block">
                {pageContent.section1.ctaText}
              </a>
            </div>
          </div>
        </div>
      </div>
      <Img {...IMAGE_BACKGROUND_PROPS} objectFit="contain" alt="Background"
        fluid={props.data.backgroundImage.childImageSharp.fluid}/>
    </section>;
  }

  function renderSection2() {
    let headerText;
    if (isEmpty(pageContent.section2.headerText) === false) {
      headerText = <h2 className="font-bold text-xl text-center leading-tight uppercase">
        {pageContent.section2.headerText}
      </h2>;
    }
    let subHeaderText;
    if (isEmpty(pageContent.section2.subHeaderText) === false) {
      subHeaderText = <h3 className="font-serif italic text-2xl sm:text-4xl lg:text-6xl text-center leading-tight mt-4 whitespace-pre-wrap">
        {pageContent.section2.subHeaderText}
      </h3>;
    }
    return <section>
      <div className="container mx-auto px-8 pt-32 lg:py-0 relative text-black">
        {headerText}
        {subHeaderText}
        <div className="text-center mt-16">
          <Img fluid={props.data.cloudPartnersAwsImage.childImageSharp.fluid}
            alt="AWS"
            className="w-full inline-block pointer-events-none"
            style={{maxWidth: "220px"}}/>
        </div>
      </div>
    </section>;
  }

  function renderSection3() {
    return <section className="relative bg-white text-black my-16 sm:my-32">
      <div className="container mx-auto px-8 z-10 relative sm:flex flex-row-reverse">
        <div className="sm:w-1/2 mb-8 sm:mb-0">
          <Img fluid={props.data.bestPracticeImage.childImageSharp.fluid}
            className="pointer-events-none"/>
        </div>
        <div className="sm:w-1/2 sm:mt-16 lg:mt-32">
          <h2 className="font-bold text-xl leading-tight uppercase">
            {pageContent.section3.headerText}
          </h2>
          <h3 className="font-serif italic text-4xl lg:text-6xl leading-tight mt-4 whitespace-pre-wrap">
            {pageContent.section3.subHeaderText}
          </h3>
          <p className="mt-4 whitespace-pre-wrap">
            {pageContent.section3.copy}
          </p>
        </div>
      </div>
    </section>;
  }

  function renderSection4() {
    return <section className="relative bg-white text-black my-16 sm:my-32">
      <img src={radiusCapGrayTopSmImage} alt="Background"
        className="w-full z-1 pointer-events-none"/>
      <div style={{background: "#F7F7F7", marginTop: "-2px"}}>
        <div className="container mx-auto px-8 pt-4 pb-16 md:py-16 z-10 relative sm:flex">
          <div className="sm:w-3/5 sm:pr-16 mb-4 sm:mb-0">
            <Img fluid={props.data.screenImage.childImageSharp.fluid}
              className="pointer-events-none"/>
          </div>
          <div className="sm:w-2/5">
            <h2 className="font-bold text-xl leading-tight uppercase">
              {pageContent.section4.headerText}
            </h2>
            <h3 className="font-serif italic text-4xl lg:text-6xl leading-tight mt-4 whitespace-pre-wrap">
              {pageContent.section4.subHeaderText}
            </h3>
            <p className="mt-4">
              {pageContent.section4.copy}
            </p>
            <a href={pageContent.section4.ctaUrl} className="btn btn-orange mt-6 inline-block">
              {pageContent.section4.ctaText}
            </a>
          </div>
        </div>
      </div>
      <img src={radiusCapGrayBottomSmImage} alt="Background"
        className="w-full z-1 pointer-events-none"/>
    </section>;
  }

  function renderSection5() {
    let items = pageContent.section5.items.map((item, index) => {
      let iconImage;
      if (index === 0) {
        iconImage = icon1Image;
      } else if (index === 1) {
        iconImage = icon2Image;
      } else if (index === 2) {
        iconImage = icon3Image;
      } else if (index === 3) {
        iconImage = icon4Image;
      } else if (index === 4) {
        iconImage = icon5Image;
      } else if (index === 5) {
        iconImage = icon6Image;
      }
      return <div key={`item-${index}`} className="sm:w-1/3">
        <div className="px-0 sm:px-8 py-4 sm:py-8">
          <img src={iconImage} alt="Icon" width={48}
            className="mb-6 pointer-events-none"/>
          <h2 className="font-bold text-lg leading-tight">
            {item.headerText}
          </h2>
          <p className="mt-2">
            {item.copy}
          </p>
        </div>
      </div>;
    });
    return <section className="relative bg-white text-black my-16 sm:my-32">
      <div className="container mx-auto px-8 pt-4 z-10 relative flex flex-wrap">
        {items}
      </div>
    </section>;
  }

  function renderSection6() {
    return <section className="relative bg-white text-black mt-16 sm:mt-32">
      <img src={radiusCapGrayTopSmImage} alt="Background"
        className="w-full z-1 pointer-events-none"/>
      <div style={{background: "#F7F7F7", marginTop: "-2px"}}>
        <div className="container mx-auto px-8 pb-8 md:pb-32 z-10 relative">
          <div className="hidden sm:block">
            <Img fluid={props.data.diagram1Image.childImageSharp.fluid}
              className="pointer-events-none"/>
          </div>
          <div className="block sm:hidden text-center justify-center">
            <Img fluid={props.data.diagram2Image.childImageSharp.fluid}
              className="mx-auto pointer-events-none"
              style={{maxWidth: "320px"}}/>
          </div>
          <div className="max-w-2xl mx-auto -mt-4">
            <div className={style.diagramTextPlacement + " border-t-4 sm:border-t-0 sm:border-l-4 border-black pl-0 sm:pl-4 pt-4 sm:pt-0"}>
              <h2 className="font-bold text-xl leading-tight uppercase">
                {pageContent.section6.headerText}
              </h2>
              <p className="mt-4">
                {pageContent.section6.copy}
              </p>
            </div>
          </div>
          <div className="text-center mt-8 sm:mt-16 mb-8 sm:mb-0">
            <a href={pageContent.section4.ctaUrl}
              className="btn btn-orange inline-block">
              {pageContent.section4.ctaText}
            </a>
          </div>
        </div>
      </div>
    </section>;
  }

  function renderSection7() {
    let items = pageContent.section7.items.map((item, index) => {
      let iconImage;
      if (index === 0) {
        iconImage = iconBuyImage;
      } else if (index === 1) {
        iconImage = iconLaunchImage;
      } else if (index === 2) {
        iconImage = iconBillImage;
      }
      return <div key={`item-${index}`} className="mt-8 flex">
        <img src={iconImage} alt="Icon" className="mr-6 pointer-events-none"/>
        <div>
          <h2 className="font-bold text-lg leading-tight uppercase">
            {item.headerText}
          </h2>
          <p className="mt-2">
            {item.copy}
          </p>
        </div>
      </div>;
    });
    return <section className="relative text-white sm:mb-32">
      <img src={radiusCapBlackTopSmImage} alt="Background"
        className="w-full z-1 pointer-events-none relative"
        style={{background: "#F7F7F7"}}/>
      <div className="bg-black relative z-10 pb-16 sm:pb-0 mt-0 sm:-mt-px">
        <div className="container mx-auto px-8 pt-16 md:pt-32 z-10 relative">
          <div className="sm:flex">
            <div className="sm:w-1/2">
              <h2 className="font-bold text-xl leading-tight uppercase">
                {pageContent.section7.headerText}
              </h2>
              <h3 className="font-serif italic text-4xl lg:text-6xl leading-tight mt-4 whitespace-pre-wrap">
                {pageContent.section7.subHeaderText}
              </h3>
              <p className="mt-8 mb-16">
                Use <a href={pageContent.section7.awsMarketplaceUrl} className="underline text-orange" {...EXTERNAL_LINK_PROPS}>AWS Marketplace</a>’s 1-Click deployment to launch Warden and pay for it on your AWS bill.
              </p>
            </div>
          </div>
          {items}
          <div className="mt-16 sm:mt-32">
            <h2 className="font-bold text-xl leading-tight uppercase text-center">
              Pricing (Per AWS Account)
            </h2>
            <div className="sm:flex justify-center mt-8">
              <div className="bg-white max-w-full mx-auto sm:mx-0 sm:mr-4" style={{width: "22rem"}}>
                <div className="text-center pt-6 pb-4">
                  <img src={icon1mImage} alt="1 Month" width={60}
                    className="inline-block pointer-events-none"/>
                  <div className="mt-2 text-black text-xs uppercase text-gray-500">1 Month</div>
                </div>
                <div style={{background: "#FFE8D6"}}>
                  <h2 className="font-bold text-3xl text-black text-center align-top leading-none py-4">
                    <span className="text-base align-top mr-1 inline-block" style={{marginTop: "2px"}}>USD</span>
                    300
                  </h2>
                </div>
                <div className="text-center py-6">
                  <a href="/contact/" className="btn btn-orange-outline inline-block">
                    Contact Us
                  </a>
                </div>
              </div>
              <div className="bg-white max-w-full mt-4 sm:mt-0 mx-auto sm:mx-0 sm:ml-4" style={{width: "22rem"}}>
                <div className="text-center pt-6 pb-4">
                  <img src={icon12mImage} alt="1 Month" width={60}
                    className="inline-block pointer-events-none"/>
                  <div className="mt-2 text-black text-xs uppercase text-gray-500">12 Months</div>
                </div>
                <div className="bg-orange">
                  <h2 className="font-bold text-3xl text-black text-center align-top leading-none py-4">
                    <span className="text-base align-top mr-1 inline-block" style={{marginTop: "2px"}}>USD</span>
                    3000
                  </h2>
                </div>
                <div className="text-center py-6">
                  <a href="/contact/" className="btn btn-orange inline-block">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Img {...IMAGE_BACKGROUND_PROPS} objectFit="cover" alt="Background"
          fluid={props.data.pricingTableBackgroundImage.childImageSharp.fluid}
          className="pointer-events-none"/>
        <div className={style.handIllustrationImage}>
          <Img fluid={props.data.handIllustrationImage.childImageSharp.fluid}
            className="pointer-events-none"/>
        </div>
      </div>
      <img src={radiusCapBlackBottomSmImage} alt="Background"
        className="w-full z-1 pointer-events-none relative bg-white mt-0 sm:-mt-px"/>
    </section>;
  }

  function renderSection8() {
    if (isEmpty(pageContent.section8)) return null;
    return <section className="relative bg-white text-black my-32 mt-16 sm:mt-64">
      <div className="container mx-auto px-8 z-10 relative">
        <h3 className="font-serif italic text-4xl lg:text-6xl text-center leading-tight mb-6">
          {pageContent.section8.headerText}
        </h3>
        <p className="max-w-xl mx-auto text-center whitespace-pre-wrap font-bold">
          {pageContent.section8.copy}
        </p>
        <div className="text-center">
          <a href={pageContent.section8.ctaUrl} className="btn btn-orange mt-6 inline-block">
            {pageContent.section8.ctaText}
          </a>
        </div>
      </div>
    </section>;
  }

  return <DefaultLayout location={props.location}
    title={pageContent.title} description={pageContent.description}>
    <div className={layoutStyle.headerPlaceholder}/>
    {renderSection1()}
    <img src={radiusTopCapImage} alt="Background"
      className="w-full z-1 pointer-events-none"/>
    <div className="relative z-10 -mt-32 overflow-hidden">
      <div className="bg-white absolute w-full" style={{top: "127px", bottom: "127px"}}/>
      {renderSection2()}
  
      {renderSection3()}
      <div style={{position: "relative", zIndex: 3000}} className="relative mt-32 mb-32 md:-mb-64">
        <TestimonialCard logo={zaveLogo} author="ALAN SCHMOLL, FOUNDER CEO" quote="With the sheer number of developer cloud accounts that we have, it is very challenging to keep track of how each is being configured. Implementing Warden to do the tedious work of monitoring was a no brainer for us. The amount of time I’ve saved and potential security incidents averted is enough reason to continue with this amazing software."/>
      </div>
      {renderSection4()}
      {renderSection5()}
      {renderSection6()}
      {renderSection7()}
      {renderSection8()}
    </div>
    <img src={radiusBottomCapImage} alt="Background"
      className="w-full z-1 -mt-32 pointer-events-none"/>
    <div className="pb-48"/>
  </DefaultLayout>;
}

export default React.memo(ProductWardenPage);

export const QUERY = graphql`
  query ProductWardenPageQuery {
    pageContent: file(relativePath: {eq: "product-pages/warden.json"}) {
      childProductPagesJson {
        title
        description
        section1 {
          headerText
          subHeaderText
          ctaText
          ctaUrl
        }
        section2 {
          headerText
          subHeaderText
        }
        section3 {
          headerText
          subHeaderText
          copy
        }
        section4 {
          headerText
          subHeaderText
          copy
          ctaText
          ctaUrl
        }
        section5 {
          items {
            headerText
            copy
          }
        }
        section6 {
          headerText
          copy
          ctaText
          ctaUrl
        }
        section7 {
          headerText
          subHeaderText
          awsMarketplaceUrl
          items {
            headerText
            copy
          }
        }
        section8 {
          headerText
          copy
          ctaText
          ctaUrl
        }
      }
    }
    backgroundImage: file(relativePath: {eq: "background-products-item.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: PNG, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cloudPartnersAwsImage: file(relativePath: {eq: "cloud-partners-aws.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: PNG, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    diagram1Image: file(relativePath: {eq: "warden-diagram-1.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: PNG, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    diagram2Image: file(relativePath: {eq: "warden-diagram-2.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: PNG, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    bestPracticeImage: file(relativePath: {eq: "warden-best-practice.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: PNG, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    screenImage: file(relativePath: {eq: "warden-screen.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: PNG, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    handIllustrationImage: file(relativePath: {eq: "warden-hand-illustration.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: PNG, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    pricingTableBackgroundImage: file(relativePath: {eq: "warden-pricing-table-background.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: PNG, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
